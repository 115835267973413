<template>
  <div class="text-left">
    <div
      v-for="(item, index) in rows"
      :key="index"
      class="list item d-flex justify-content-between align-items-center pbz-font body-md-medium"
    >
      <div class="col-4 text-truncate">
        <LinkRoute :to="'/formula-variable/edit/' + item.id" :title="item.symbol">
          {{ item.symbol }}
        </LinkRoute>
      </div>
      <div class="col text-truncate">
        <span :title="item.name">{{ item.name }}</span>
      </div>

      <div class="d-flex justify-content-end align-items-center">
        <LinkRoute
          v-if="permission.includes('WRITE')"
          :to="'/formula-variable/edit/' + item.id"
          class="ant-btn ant-btn-link ant-btn-icon-only mr-1"
        >
          <a-icon type="edit" />
        </LinkRoute>
        <a-button 
          v-if="permission.includes('WRITE')"
          type="link"
          icon="delete"
          @click="onClickDelete(item)"
        />
      </div>
    </div>

    <ModalDelete
      :visible="visibleModalDelete"
      :loading="confirmLoading"
      :loading-start="loadingCheckUsed"
      :varname="dataDelete.symbol || ''"
      :data-used="dataDelete.dataUsed || []"
      :type="$t('utils.variable')"
      @ok="onOkDelete"
      @cancel="onCloseModalDelete"
    />
  </div>
</template>

<script>
import { isUsedVariable, deleteVariable } from '@/api/price'
import LinkRoute from '@/components/ConsoleLink/LinkRoute.vue'
import ModalDelete from '@/components/Price/ModalDelete.vue'

export default {
  components: {
    LinkRoute,
    ModalDelete,
  },
  props: ['rows', 'permission'],
  data() {
    return {
      visibleModalDelete: false,
      confirmLoading: false,
      loadingCheckUsed: false,
      dataDelete: {},
    }
  },
  methods: {
    onCloseModalDelete() {
      this.visibleModalDelete = false
    },
    async onClickDelete(item) {
      this.loadingCheckUsed = true
      this.visibleModalDelete = true

      const { categories, businessId, id } = item

      let dataUsed = []

      await isUsedVariable({
        type: categories,
        params: {
          variable_id: id,
          business_id: businessId,
        },
      })
      .then(({ data: { data: response } }) => {
        if (response?.length) {
          dataUsed = response
        }
        this.loadingCheckUsed = false
      })
      .catch((err) => {
        if (err.code !== 'ERR_CANCELED') {
          this.$notification.destroy()
          this.$notification.error({
            message: this.$t('utils.failed'),
            description: err?.response?.data?.message || err.message,
          })
          setTimeout(() => {
            this.visibleModalDelete = false
          }, 500)
        }
      })

      this.dataDelete = { ...item, dataUsed }
    },
    async onOkDelete() {
      this.confirmLoading = true

      const { businessId, id, symbol, categories } = this.dataDelete
      await deleteVariable({
        id,
        data: {
          business_id: businessId, 
          category: categories,
        },
      })
      .then(() => {
        this.$notification.destroy()
        this.$notification.success({
          message: this.$t('utils.deleted'),
          description: this.$t('utils.variable') + ' ' + this.$t('price_setting.success_delete_msg', { name: symbol }),
        })
        this.$emit('onDeleteSuccess')
        this.dataDelete = {}
        this.visibleModalDelete = false
      })
      .catch(err => {
        this.$notification.destroy()
        this.$notification.error({
          message: this.$t('utils.failed'),
          description: err.response.data.message || err.message,
        })
      })
      .finally(() => { this.confirmLoading = false })
    },
  },
}
</script>

<style lang="scss" scoped>
.list {
  border-radius: 5px !important;
  border: 1px solid #ccc !important;

  &.item {
    height: 48px !important;
    padding: 6px !important;
    margin-bottom: 6px;
  }
}
</style>
