<template>
  <div class="w-100 pb-3">
    <div class="d-flex justify-content-between align-items-center">
      <div class="pbz-font subtitle-sm-medium">
        {{ $t('sideBar.formula_variable') }}
      </div>

      <div class="d-flex align-items-center">
        <a-input-search
          size="large"
          style="width:400px"
          :disabled="loading"
          :placeholder="$t('utils.search')"
          :value="$route.query.q || ''"
          @change="handleSearchBar"
        />
      </div>

      <LinkRoute
        v-if="$route.query.business_id && permission[1] === 'WRITE'"
        class="ant-btn ant-btn-primary ant-btn-lg"
        to="/formula-variable/add"
      >
        {{ $t('utils.add') }} {{ $t('utils.variable') }}
      </LinkRoute>
      <div v-else class="px-3" />
    </div>

    <div class="pt-4 pb-3">
      <div class="py-2 my-2 pbz-font caption-md-medium row no-gutters align-items-center list head">
        <div class="col-4 pl-4">
          {{ $t('price_setting.label_variable') }}
        </div>
        <div class="col pl-3">
          {{ $t('price_setting.label_description') }}
        </div>
      </div>

      <LoadingListTable v-if="loading" />
      <template v-else>
        <ErrorInfo v-if="errorRows" :error-type="errorRows" />
        <template v-else>
          <ListFormulaVariable
            v-if="dataRows.length"
            :rows="dataRows"
            :permission="permission"
            @onDeleteSuccess="fetchRows"
          />
          <EmptyInfo
            v-else
            :title="$t('formula_variable.empty_title')"
            :description="$t('formula_variable.empty_msg')"
            add-url="/formula-variable/add"
            :button-label="$t('formula_variable.add')"
          />
        </template>
      </template>
    </div>
    <Pagination
      v-if="!loading && dataRows.length"
      class="text-right"
      :total="total_rows"
      :page-size="limit"
      :total-length-per-page="dataRows.length"
      :default-page="page"
      @changePage="changePage"
    />
  </div>
</template>

<script>
import { getPriceVariables } from '@/api/price'
import ListFormulaVariable from '@/components/Price/Formula-Variable/List'
import Pagination from '@/components/Pagination'
import LoadingListTable from '@/components/Loading/ListTable'
import EmptyInfo from '@/components/EmptyInfo'
import ErrorInfo from '@/components/ErrorInfo'
import LinkRoute from '@/components/ConsoleLink/LinkRoute.vue'

const plainOptions = []
const defaultCheckedList = []

export default {
  components: {
    ListFormulaVariable,
    Pagination,
    LoadingListTable,
    EmptyInfo,
    ErrorInfo,
    LinkRoute,
  },
  data() {
    return {
      loading: true,
      total_rows: 0,
      dataRows: [],
      errorRows: false,
      checkedList: defaultCheckedList,
      indeterminate: false, // true
      checkAll: false,
      plainOptions,
    }
  },
  computed: {
    page() {
      return +this.$route.query.page || 1
    },
    limit() {
      return +this.$route.query.limit || 10
    },
    permission() {
      return this.$store.getters['user/can'](this.$route.meta.key)
    },
  },
  watch: {
    $route() {
      this.fetchRows()
    },
    // permission(newValue) {
    //   if(!newValue.length) {
    //     this.$router.push({path: '/error/403', query: {...this.$route.query}})
    //   }
    // },
  },
  mounted() {
    this.fetchRows()
  },
  methods: {
    async fetchRows() {
      this.loading = true
      this.total_rows = 0
      this.errorRows = false

      const { business_id, q } = this.$route.query

      await getPriceVariables({
        type: 'FORMULA',
        params: {
          business_id,
          q,
          page: this.page,
          limit: this.limit,
        },
      })
      .then(({ data }) => {
        this.dataRows = data?.data || []
        this.total_rows = data?.total_rows || 0
      })
      .catch(err => {
        this.dataRows = []
        this.total_rows = 0
        if (err.code !== 'ERR_CANCELED') this.errorRows = err.response.status
      })
      .finally(() => this.loading = false)
    },
    handleSearchBar(e) {
      const value = e.target.value.trim()
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const { query } = this.$route
        const UND = undefined
        if (value.length) {
          this.$router.push({
            query: { ...query, q: value, page: UND },
          })
        } else {
          query.q && this.$router.replace({
            ...this.$route,
            query: { ...query, q: UND, page: UND },
          })
        }
      }, 2e3)
    },
    changePage(page) {
      this.$router.push({
        query: { ...this.$route.query, page },
      })
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.list {
    border-radius: 5px !important;
    border: 1px solid #CCC !important;

    &.head {
      color: #999;
      height: 38px !important;
    }
  }
</style>
